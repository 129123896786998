export const SETTINGS = {
  appName: "GTI Sales Portal",
  authTokenName: "gtisales_access_token",
  refreshTokenName: "gtisales_refresh_token",
  supportEmail: "example@example.com",
  mobileBreakpoint: 1025,
  timezone: "America/Los_Angeles",
  logoLight:
    "https://gti.labdynamo.com/wp-content/uploads/2016/11/gti-logo_wh.png",
  logoDark:
    "https://gti.labdynamo.com/wp-content/uploads/2022/03/gti-logo-copy-1.png",
  auth_token: "x-token",
};

export const PASS_REGEX =
  /^(?=(.*[a-zA-Z].*){2,})(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,25}$/;

export const EMAIL_REGEX =
  /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/;

export const STATES = [
  {
    value: "AL",
    label: "AL",
  },
  {
    value: "AK",
    label: "AK",
  },
  {
    value: "AS",
    label: "AS",
  },
  {
    value: "AZ",
    label: "AZ",
  },
  {
    value: "AR",
    label: "AR",
  },
  {
    value: "CA",
    label: "CA",
  },
  {
    value: "CO",
    label: "CO",
  },
  {
    value: "CT",
    label: "CT",
  },
  {
    value: "DE",
    label: "DE",
  },
  {
    value: "DC",
    label: "DC",
  },
  {
    value: "FM",
    label: "FM",
  },
  {
    value: "FL",
    label: "FL",
  },
  {
    value: "GA",
    label: "GA",
  },
  {
    value: "GU",
    label: "GU",
  },
  {
    value: "HI",
    label: "HI",
  },
  {
    value: "ID",
    label: "ID",
  },
  {
    value: "IL",
    label: "IL",
  },
  {
    value: "IN",
    label: "IN",
  },
  {
    value: "IA",
    label: "IA",
  },
  {
    value: "KS",
    label: "KS",
  },
  {
    value: "KY",
    label: "KY",
  },
  {
    value: "LA",
    label: "LA",
  },
  {
    value: "ME",
    label: "ME",
  },
  {
    value: "MH",
    label: "MH",
  },
  {
    value: "MD",
    label: "MD",
  },
  {
    value: "MA",
    label: "MA",
  },
  {
    value: "MI",
    label: "MI",
  },
  {
    value: "MN",
    label: "MN",
  },
  {
    value: "MS",
    label: "MS",
  },
  {
    value: "MO",
    label: "MO",
  },
  {
    value: "MT",
    label: "MT",
  },
  {
    value: "NE",
    label: "NE",
  },
  {
    value: "NV",
    label: "NV",
  },
  {
    value: "NH",
    label: "NH",
  },
  {
    value: "NJ",
    label: "NJ",
  },
  {
    value: "NM",
    label: "NM",
  },
  {
    value: "NY",
    label: "NY",
  },
  {
    value: "NC",
    label: "NC",
  },
  {
    value: "ND",
    label: "ND",
  },
  {
    value: "MP",
    label: "MP",
  },
  {
    value: "OH",
    label: "OH",
  },
  {
    value: "OK",
    label: "OK",
  },
  {
    value: "OR",
    label: "OR",
  },
  {
    value: "PW",
    label: "PW",
  },
  {
    value: "PA",
    label: "PA",
  },
  {
    value: "PR",
    label: "PR",
  },
  {
    value: "RI",
    label: "RI",
  },
  {
    value: "SC",
    label: "SC",
  },
  {
    value: "SD",
    label: "SD",
  },
  {
    value: "TN",
    label: "TN",
  },
  {
    value: "TX",
    label: "TX",
  },
  {
    value: "UT",
    label: "UT",
  },
  {
    value: "VT",
    label: "VT",
  },
  {
    value: "VI",
    label: "VI",
  },
  {
    value: "VA",
    label: "VA",
  },
  {
    value: "WA",
    label: "WA",
  },
  {
    value: "WV",
    label: "WV",
  },
  {
    value: "WI",
    label: "WI",
  },
  {
    value: "WY",
    label: "WY",
  },
];

export const GENDER = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Other",
    label: "Prefer not to say",
  },
];

export const RACE = [
  {
    value: "American Indian",
    label: "American Indian",
  },
  {
    value: "Asian",
    label: "Asian",
  },
  {
    value: "African American",
    label: "African American",
  },
  {
    value: "Hispanic",
    label: "Hispanic",
  },
  {
    value: "White",
    label: "White",
  },
];

export const EMAIL_LIST =
  "holds@gtilaboratories.com,CALab@gtilaboratories.com,operations@gtilaboratories.com";
