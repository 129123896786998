// TOP LEVEL IMPORTS
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ME } from "apollo/queries/userQueries";
// PUBLIC
import PublicRoute from "routes/PublicRoute";
import AdminRoute from "routes/AdminRoute";
// LAYOUTS
import PublicLayout from "layouts/PublicLayout";
import AdminLayout from "layouts/AdminLayout";
// AUTH
import {
  Signin as LoginView,
  MasterClaimDetail as MasterClaimDetailView,
  OrderDetail as OrderDetailView,
  RepReview as RepReviewView,
  SampleCounts as SampleCountsView,
  UserManagement as UserManagementView,
  CreateUser as CreateUserView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  ImportCSV as ImportCsvView,
  HoldReason as HoldReasonView,
  UserSettings as UserSettingsView,
} from "views";
import Spinner from "react-spinkit";

const AppRoutes = () => {
  const { data, loading, error } = useQuery(ME, {
    errorPolicy: "all",
    // pollInterval: 10000, // rerun currentUser every X milliseconds
  });

  if (loading) return <Spinner name="line-scale-pulse-out-rapid" />;

  if (error) {
    console.log(error);
  }

  let currentUser = data && data.me;

  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/"
          component={LoginView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/master-claim-detail"
          component={MasterClaimDetailView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/order-detail"
          component={OrderDetailView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/rep-review"
          component={RepReviewView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/sample-counts"
          component={SampleCountsView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/user-management"
          component={UserManagementView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/user-management"
          component={UserManagementView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/upload-csv"
          component={ImportCsvView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/rep-hold"
          component={HoldReasonView}
          currentUser={currentUser}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/user-settings"
          component={UserSettingsView}
          currentUser={currentUser}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/register-user"
          component={CreateUserView}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/forgot-pass"
          component={ForgotPasswordView}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/reset-pass"
          component={ResetPasswordView}
        />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
